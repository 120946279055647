import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import Footer from '../Footer';
import { NavLink } from 'react-router-dom';
import Navbar from '../Navbar';
import bg from '../../img/Ellipse 3.png'
import dbg from "../../img/b.png";
import salarybg from "../../img/salary-bg.svg";
import CareerEduFaq from './CareerEduFaq';
import EduDirectFaq from './EduDirectFaq';

const Edudirect = () => {
    const [isLogin, setIsLogin] = useState(false);


    const userLoginData = useSelector((state:any) => state.data.login.value);
  
    React.useEffect(() => {
      if (!userLoginData.token) {
        setIsLogin(false)
      }
  
      // console?.log(userLoginData)
  
      if (userLoginData.token) {
        setIsLogin(true)
      }
      //  (userLoginData.token ?
      //   ''
      //   :
      //     navigate('/sign-in'));
    }, []);
  return (
    <>
    <Navbar />
    {/* <!-- Start block --> */}
    <section className=" body-font font-poppins md:pt-10 pt-20">
      <div className="grid lg:grid-cols-12 max-w-screen-xl mx-auto px-8   lg:gap-8 xl:gap-0 lg:pt-16 pt-20">
        <div className="mr-auto place-self-center lg:col-span-7">
        <div className="ml-[86px] pb-1 lg:block hidden">
            <div className="bg-[#FFBA40] h-3 w-3 rounded-full"></div>
          </div>
          <div></div>
          <span className="text-[12px] bg-orange-800 text-white rounded-full px-[5px] py-[1px]">Coming soon</span>
          <h1 className="lg:max-w-xl mb-4 lg:text-[40px] text-[30px] text-[#000] font-[700] md:leading-[52px] leading-[48px] tracking-normal  ">
          EDUDIRECT <br />
Your Guaranteed Gateway to Finnish Universities of Applied Sciences

          </h1>
        
          {/* <div className=" -mt-8  lg:flex justify-end mr-[150px]  hidden">
            <div className="bg-[#EE1D52] h-6 w-6 rounded-full"></div>
          </div> */}
          {/* <p className=" max-w-[640px] lg:pr-[30px]  mb-6 mt-3 font-normal text-[#777777] lg:mb-8 lg:text-[22px] md:leading-[30px] text-sm ">
          Elevate Your Learning Journey with our Finnish Language Program
          </p> */}

          <div className="flex justify-between">
            {/* {isLogin ?  */}
            <a  href="https://forms.gle/S2Zj7bn6YxsDi7w97"  rel="noopener noreferrer" target="_blank">
              <button
                type="submit"
                className="bg-gradient-to-r from-[#01963C] to-[#158FD3] py-[16px] px-[40px]  text-sm font-medium text-white rounded-[40px] "
              >
                Join Our Waitlist
              </button>
              </a>
               {/* : 
                <NavLink to={"/terms"} className="">
                <button
                  type="submit"
                  className="bg-gradient-to-r from-[#01963C] to-[#158FD3] py-[16px] px-[40px]  text-sm font-medium text-white rounded-[40px] "
                >
                  Get Started
                </button>
              </NavLink>} */}
          
            <div className="flex flex-col">
            <div className=" mb-4  lg:flex justify-center -mt-[50px] mr-[100px]  hidden">
              <div className="bg-[#EE1D52] h-5 w-5 rounded-full"></div>
            </div>
              <div className=" mb-4  lg:flex justify-end mr-[40px]  hidden">
                <div className="bg-[#0174B4] h-3 w-3 rounded-full"></div>
              </div>
            </div>
          </div>
          <div className=" mb-4  lg:flex justify-center -mt-[30px] mr-[100px]  hidden">
              <div className="bg-[#1DB459] h-5 w-5 rounded-full"></div>
            </div>
        </div>
        <div className=" my-6 lg:mt-0 lg:col-span-5 lg:flex w-full">
          <img src="/images/Group 1000006354.svg" alt="hero" />
        </div>
      </div>
    </section>
    {/* <!-- End block --> */}


      
    {/* <!-- Start block --> */}
    <section className=" body-font font-poppins md:pt-10 pt-10">
      <div className="grid lg:grid-cols-12 max-w-screen-xl mx-auto px-8 lg:px-14  pb-8  lg:gap-8 xl:gap-0">
        <div className="mr-auto place-self-center hidden lg:flex lg:col-span-6">
          <img src="/images/Group 1000006349.svg" alt="hero" />
        </div>
        <div className=" lg:col-span-6">
          <h1 className="lg:max-w-3xl mb-4 lg:text-[40px] text-[35px] text-[#1DB459] font-[700] md:leading-[52px] leading-[48px] tracking-normal">
       Why Choose <span className="text-black">EduDirect?</span>
          </h1>
          <p className=" mt-3 mb-4 font-normal text-[#777777] lg:text-base text-sm">
          Are you dreaming of studying in Finland without the hassle of entrance exams or language barriers? EduDirect is here to make that dream a reality. Our innovative pathway program offers a seamless route to the prestigious Universities of Applied Sciences (UAS) in Finland, all while studying in English. Here’s why EduDirect is the perfect choice for your higher education journey:
          </p>
          {/* {isLogin ?  */}
          <a  href="https://forms.gle/S2Zj7bn6YxsDi7w97"  rel="noopener noreferrer" target="_blank">
              <button
                type="submit"
                className="bg-gradient-to-r from-[#01963C] to-[#158FD3] py-[16px] px-[40px]  text-sm font-medium text-white rounded-[40px] "
              >
            Join Our Waitlist
              </button>
              </a>
               {/* : 
                <NavLink to={"/terms"} className="">
                <button
                  type="submit"
                  className="bg-gradient-to-r from-[#01963C] to-[#158FD3] py-[16px] px-[40px]  text-sm font-medium text-white rounded-[40px] "
                >
                  Get Started
                </button>
              </NavLink>} */}
        </div>
        <div className="mr-auto place-self-center lg:hidden flex lg:col-span-7">
          <img src="/images/careeredu2.png" alt="hero" />
        </div>
      </div>
    </section>
    {/* <!-- End block --> */}

       {/* <!-- Start block --> */}
   <section className=" body-font font-poppins md:pt-10 pt-10">
        <div className=" px-8 lg:px-14  ">
          <h1 className=" mb-4 lg:text-[48px] text-[35px] text-[#000] font-[700] md:leading-[52px] leading-[48px] tracking-normal  ">
          Benefits of EDUDIRECT
          </h1>

          <div className=" gap-4 grid lg:grid-cols-3 md:grid-cols-3 xl:gap-4 lg:py-6 lg:mt-6 ">
       <div className="px-6 py-[15px]  rounded-[10px]  bg-[#F6F6F6]">
              <div className="flex items-start gap-3">
          <img src="/images/1.svg" className='' alt="hero" />
             <h4 className='text-[#3E3D3D] text-[15px] md:text-[18px] font-[600]'>
             9-Month Online UAS Abridged Program: Complete your foundational studies online in just 9 months, from the comfort of your home.
             </h4>
                </div>
              </div>   

                 <div className="px-6 py-[15px]  rounded-[10px]  bg-[#F6F6F6]">
              <div className="flex items-start gap-3">
          <img src="/images/2.svg" className='' alt="hero" />
             <h4 className='text-[#3E3D3D] text-[15px] md:text-[18px] font-[600]'>
             Eligibility to Apply to European Universities: Open doors to universities across Europe with your Finnish UAS credentials.
             </h4>
                </div>
              </div> 

               <div className="px-6 py-[15px]  rounded-[10px]  bg-[#F6F6F6]">
              <div className="flex items-start gap-3">
          <img src="/images/3.svg" className='' alt="hero" />
             <h4 className='text-[#3E3D3D] text-[15px] md:text-[18px] font-[600]'>
             30 ECTs Higher Education Studies: Earn 30 ECTs credits that provide direct access to Finnish UAS.
            </h4>
                </div>
              </div>   

                  <div className="px-6 py-[15px]  rounded-[10px]  bg-[#F6F6F6]">
              <div className="flex items-start gap-3">
          <img src="/images/4.svg" className='' alt="hero" />
             <h4 className='text-[#3E3D3D] text-[15px] md:text-[18px] font-[600]'>
             Choose from 8 Bachelor’s Programs in English: Select from a diverse range of programs taught entirely in English.
             </h4>
                </div>
              </div> 

               <div className="px-6 py-[15px]  rounded-[10px]  bg-[#F6F6F6]">
              <div className="flex items-start gap-3">
          <img src="/images/5.svg" className='' alt="hero" />
             <h4 className='text-[#3E3D3D] text-[15px] md:text-[18px] font-[600]'>
             Reduced Bachelor Study Periods: Benefit from about 6 months of reduced study time, getting you to your degree faster.
             </h4>
                </div>
              </div>    

              <div className="px-6 py-[15px]  rounded-[10px]  bg-[#F6F6F6]">
              <div className="flex items-start gap-3">
          <img src="/images/6.svg" className='' alt="hero" />
             <h4 className='text-[#3E3D3D] text-[15px] md:text-[18px] font-[600]'>
             No IELTS Required: Skip the IELTS exam and focus on your studies.
             </h4>
                </div>
              </div>  

                 <div className="px-6 py-[15px]  rounded-[10px]  bg-[#F6F6F6]">
              <div className="flex items-start gap-3">
          <img src="/images/7.svg" className='' alt="hero" />
             <h4 className='text-[#3E3D3D] text-[15px] md:text-[18px] font-[600]'>
             30% Off the First Tuition Fee: Enjoy a significant discount on your first year of tuition.
             </h4>
                </div>
              </div>  

              <div className="px-6 py-[15px]  rounded-[10px]  bg-[#F6F6F6]">
              <div className="flex items-start gap-3">
          <img src="/images/8.svg" className='' alt="hero" />
             <h4 className='text-[#3E3D3D] text-[15px] md:text-[18px] font-[600]'>
             Learn Finnish for Reduced Tuition Fees: Master the Finnish language and reduce your tuition costs even further.
             </h4>
                </div>
              </div>  

              <div className="px-6 py-[15px]  rounded-[10px]  bg-[#F6F6F6]">
              <div className="flex items-start gap-3">
          <img src="/images/9.svg" className='' alt="hero" />
             <h4 className='text-[#3E3D3D] text-[15px] md:text-[18px] font-[600]'>
             Guaranteed Admission: Secure your spot in a Finnish UAS without being placed on a waitlist.
             </h4>
                </div>
              </div>  
         

        
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

        {/* <!-- Start block --> */}
        <section className=" body-font font-poppins md:pt-10 pt-10">
      <div className="grid lg:grid-cols-12 max-w-screen-xl mx-auto px-8 lg:px-14  pb-8  lg:gap-8 xl:gap-0">
        <div className="mr-auto place-self-center hidden lg:flex lg:col-span-6">
          <img src="/images/Group 10000063491.svg" alt="hero" />
        </div>
        <div className=" lg:col-span-6">
          <h1 className="lg:max-w-3xl mb-4 lg:text-[40px] text-[35px] text-[#1DB459] font-[700] md:leading-[52px] leading-[48px] tracking-normal">
    How it <span className="text-black">Works</span>
          </h1>
          <p className=" mt-3 mb-4 font-normal text-[#777777] lg:text-base text-sm">
          EduDirect offers a streamlined process for international students to gain direct admission to Finnish Universities of Applied Sciences. Our comprehensive program prepares you academically and culturally, ensuring a smooth transition to your new academic life in Finland.
         </p>
          {/* {isLogin ?  */}
          <a  href="https://forms.gle/S2Zj7bn6YxsDi7w97"  rel="noopener noreferrer" target="_blank">
              <button
                type="submit"
                className="bg-gradient-to-r from-[#01963C] to-[#158FD3] py-[16px] px-[40px]  text-sm font-medium text-white rounded-[40px] "
              >
               Join Our Waitlist
              </button>
              </a> 
              {/* : 
                <NavLink to={"/terms"} className="">
                <button
                  type="submit"
                  className="bg-gradient-to-r from-[#01963C] to-[#158FD3] py-[16px] px-[40px]  text-sm font-medium text-white rounded-[40px] "
                >
                  Get Started
                </button>
              </NavLink>} */}
        </div>
        <div className="mr-auto place-self-center lg:hidden flex lg:col-span-7">
          <img src="/images/careeredu2.png" alt="hero" />
        </div>
      </div>
    </section>
    {/* <!-- End block --> */}

    {/* <!-- Start block --> */}
   


    <section className="body-font font-poppins md:pty-10 py-10">
  <div className="">
    <div
       style={{
        backgroundImage: `url(${dbg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
      className="pb-7 w-full pt-7 relative flex items-center justify-center"
    >
          {/* <img src="/images/Frame 1171276471 (1).png" alt="hero" /> */}
   
   <div>
    <h4 className='text-white text-[30px] text-center pb-6 font-[600]'>Indicate Your Interest and Help Us Improve</h4>
    <h4 className='text-white text-[18px] max-w-[920px] mb-7 text-center font-[300]'>We’re excited to launch EduDirect and want to ensure it meets your needs. Please take a moment to fill out our survey. Your feedback is invaluable to us, and by completing the survey, you’ll be among the first to be notified when the program launches.</h4>
  <div className='flex justify-center'>
  <a  href="https://forms.gle/S2Zj7bn6YxsDi7w97"  rel="noopener noreferrer" target="_blank">
              <button
                type="submit"
                className="bg-white  py-[16px] px-[50px]  text-sm font-medium text-[#1DB459] rounded-[40px] "
              >
               Fill the form
              </button>
              </a>
  </div>
   </div>
    </div>
  </div>
</section>


    {/* <!-- End block --> */}
   

    {/* <!-- Start block --> */}
    <section className="">
      <EduDirectFaq />
    </section>

    {/* <!-- End block --> */}
    <Footer />
  </>
  )
}

export default Edudirect