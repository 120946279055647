import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Navbar from "../Navbar";
import Faq from "../Faq";
import Footer from "../Footer";
import AsapEduGoFaq from "../AsapEduGoFaq";
import { useSelector } from "react-redux";

function AsapEduGo() {

  const [isLogin, setIsLogin] = useState(false);

  const userLoginData = useSelector((state:any) => state.data.login.value);

  React.useEffect(() => {
    if (!userLoginData.token) {
      setIsLogin(false)
    }

    // console?.log(userLoginData)

    if (userLoginData.token) {
      setIsLogin(true)
    }
    //  (userLoginData.token ?
    //   ''
    //   :
    //     navigate('/sign-in'));
  }, []);

  return (
    <>
      <Navbar />
      {/* <!-- Start block --> */}
      <section className=" body-font font-poppins md:pt-10 pt-20">
        <div className="grid lg:grid-cols-12 max-w-screen-xl mx-auto px-8   lg:gap-8 xl:gap-0 lg:pt-16 pt-20">
          <div className="mr-auto place-self-center lg:col-span-7">
            <div className="ml-[86px] pb-1 lg:block hidden">
              <div className="bg-[#FFBA40] h-3 w-3 rounded-full"></div>
            </div>
            <h1 className="lg:max-w-2xl lg:text-[48px] text-[35px] text-[#000] font-[700] md:leading-[52px] leading-[48px] tracking-normal  ">
              Embark on an exhilarating journey with ASAPEDUGO
            </h1>
            <div className=" mb-4  lg:flex justify-end mr-[150px]  hidden">
              <div className="bg-[#EE1D52] h-6 w-6 rounded-full"></div>
            </div>
            <p className=" max-w-[650px] lg:pr-[30px]  mb-6 mt-3 font-normal text-[#777777] lg:mb-8 lg:text-base text-sm">
              An immersive 6-month study program designed to catapult you into
              Finnish fluency. Picture this: the first two months, you'll dive
              into the enchanting world of Finnish language and culture from the
              comfort of your own home, soaking up knowledge and building a
              solid foundation online
            </p>

            <div className="flex justify-between">
              <NavLink to={isLogin ? "/dashboard" : "/terms"} className="">
                <button
                  type="submit"
                  className="bg-gradient-to-r from-[#01963C] to-[#158FD3] py-[16px] px-[40px]  text-sm font-medium text-white rounded-[40px] "
                >
                  Get Started
                </button>
              </NavLink>
              <div className="flex flex-col">
                <div className=" mb-4  lg:flex justify-end mr-[100px]  hidden">
                  <div className="bg-[#0174B4] h-3 w-3 rounded-full"></div>
                </div>
              </div>
            </div>
            <div>
              <div className=" mb-4  lg:flex justify-center mr-[200px]  hidden">
                <div className="bg-[#1DB459] h-5 w-5 rounded-full"></div>
              </div>
            </div>
          </div>
          <div className=" my-6 lg:mt-0 lg:col-span-5 lg:flex w-full">
            <img src="/images/asapedugo1.png" alt="hero" />
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className=" body-font font-poppins md:pt-10 pt-10">
        <div className="grid lg:grid-cols-12 max-w-screen-xl mx-auto px-8 lg:px-14  pb-8  lg:gap-8 xl:gap-0">
          <div className="mr-auto place-self-center hidden lg:flex lg:col-span-7">
            <img src="/images/asapedugo2.png" alt="hero" />
          </div>
          <div className="mr-auto place-self-center lg:col-span-5">
            <h1 className="lg:max-w-2xl mb-4 lg:text-[48px] text-[35px] text-[#1DB459] font-[700] md:leading-[52px] leading-[48px] tracking-normal">
              An adventure in <span className="text-[#0071BC]">Finland </span>
            </h1>
            <p className=" mt-3 font-normal text-[#777777] lg:text-base text-sm">
              But the adventure truly begins when you step foot onto Finnish
              soil. For the next four months, you'll find yourself nestled in a
              boarding location in Finland, surrounded by the breathtaking
              beauty of the Nordic landscapes. Here, amidst the tranquility and
              charm of Finland, you'll immerse yourself in the language like
              never before.
            </p>
          </div>
          <div className="mr-auto place-self-center lg:hidden flex lg:col-span-7">
            <img src="/images/asapedugo2.png" alt="hero" />
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className=" body-font font-poppins md:pt-10 pt-10">
        <div className="grid lg:grid-cols-12 max-w-screen-xl mx-auto  px-8 lg:px-14  pb-8  lg:gap-8 xl:gap-0 ">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="lg:max-w-xl mb-4 lg:text-[48px] text-[35px] text-black font-[700] md:leading-[52px] leading-[48px] tracking-normal">
              More than just a{" "}
              <span className="text-[#1DB459]">language program</span>
            </h1>

            <p className=" max-w-[600px] lg:pr-[30px]  mb-6 mt-3 font-normal text-[#777777] lg:mb-8 lg:text-base text-sm ">
              From lively conversations with locals to hands-on experiences in
              authentic Finnish settings, every moment will be a step closer to
              mastering the language. Our expert instructors will guide you
              through 4-5 hours of intensive onsite language learning sessions,
              refining your skills and igniting your passion for Finnish.
            </p>
          </div>
          <div className=" my-6 lg:mt-0 lg:col-span-5 lg:flex w-full">
            <img src="/images/asapedugo3.png" alt="hero" />
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className=" body-font font-poppins md:pt-10 pt-10">
        <div className=" px-8 lg:px-14  ">
          <h1 className=" mb-4 lg:text-[40px] text-[35px] text-[#000] font-[700] md:leading-[52px] leading-[48px] tracking-normal  ">
            Features of ASAPEDUGO
          </h1>

          <div className=" space-y-8 grid lg:grid-cols-4 md:grid-cols-2 xl:gap-4 gap-3 lg:space-y-0 lg:py-6 lg:mt-6 ">
            {/* lg:px-14 pt-10 mx-auto  flex flex-col lg:flex-row lg:space-x-14 mb-20 */}
            <div className=" lg:mt-0 mt-10">
              <div className="px-6 py-[15px] h-[118px] rounded-[10px]  bg-[#EEFFF5]">
                {/* <div className=" "> */}
                <div className="flex gap-[10px]">
                  <div>
                    <img src="/images/easygoedu4.png" alt="hero" />
                  </div>
                  {/* </div> */}
                  <div>
                    <p className="font-normal  text-sm max-w-[200px] text-[#000000]">
                      6-month program with 4 months living and learning in
                      Finland! Start with 2 months of online prep
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className=" lg:mt-0 mt-10">
              <div className="px-6 py-[15px] h-[118px] rounded-[10px]  bg-[#F48120]/[15%]">
                {/* <div className=" "> */}
                <div className="flex gap-[10px]">
                  <div>
                    <img src="/images/easygoedu5.png" alt="hero" />
                  </div>
                  {/* </div> */}
                  <div>
                    <p className="font-normal text-sm max-w-[200px]  text-[#000000]">
                      4-5 hours Mondays to Fridays ONSITE language learning.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className=" lg:mt-0 mt-10">
              <div className="pl-6 pr-4 py-[15px] h-[118px] rounded-[10px]  bg-[#C24B9A]/[15%]">
                {/* <div className=" "> */}
                <div className="flex gap-[10px]">
                  <div>
                    <img src="/images/easygoedu6.png" alt="hero" />
                  </div>
                  {/* </div> */}
                  <div>
                    <p className="font-normal text-sm max-w-[220px]  text-[#000000]">
                      You cannot work until 4 months program is completed and
                      get accepted to study full time in Finland
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className=" lg:mt-0 mt-10">
              <div className="px-6 py-[15px] h-[118px] rounded-[10px]  bg-[#0174B4]/[15%]">
                {/* <div className=" "> */}
                <div className="flex gap-[10px]">
                  <div>
                    <img src="/images/easygoedu7.png" alt="hero" />
                  </div>
                  {/* </div> */}
                  <div>
                    <p className="font-normal  text-sm max-w-[170px]  text-[#000000]">
                      (  14,200€ ) + 100€ processing fee.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className=" body-font font-poppins md:pt-10 py-10">
        <div className="bg-gradient-to-r pt-[120px] pb-[60px] flex justify-center text-center from-[#01963C] to-[#158FD3]">
          <div>
            <p className="text-white text-[20px] max-w-[993px] font-normal">
              So, are you ready to seize the opportunity of a lifetime? Join
              ASAPEDUGO today and embark on a journey that will not only
              transform your language skills but also open doors to a world of
              opportunities in the land of a thousand lakes.
            </p>
            <div className="flex justify-center pt-7">
            {isLogin ? <a  href="https://wa.me/2349047248430?text=Hello%20I%20would%20like%20to%20speak%20to%20a%20consultant"  rel="noopener noreferrer" target="_blank">
              <button
                type="submit"
                className="bg-gradient-to-r from-[#01963C] to-[#158FD3] py-[16px] px-[40px]  text-sm font-medium text-white rounded-[40px] "
              >
                Get Started
              </button>
              </a> : 
                <NavLink to={"/terms"} className="">
                <button
                  type="submit"
                  className="bg-gradient-to-r from-[#01963C] to-[#158FD3] py-[16px] px-[40px]  text-sm font-medium text-white rounded-[40px] "
                >
                  Get Started
                </button>
              </NavLink>}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="">
        <AsapEduGoFaq />
      </section>

      {/* <!-- End block --> */}
      <Footer />
    </>
  );
}

export default AsapEduGo;
