import React, { useState } from "react";
import Navbar from "../Navbar";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { NavLink, useNavigate } from "react-router-dom";
import hero from "../../img/new-course-bg.png";
import herosmall from "../../img/new-course-small.png";
import { AuthApis } from "../../apis/authApis";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import configs from "../../configs";
import Footer from "../Footer";

//@ts-ignore
import DOMPurify from "dompurify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const CourseDetails = () => {
  const params: any = useParams();
  let partnerId: string = params?.courseId;
  const navigate = useNavigate();

  const [courseDetails, setCourseDetails] = React.useState<any>("");

  const [schoolData, setSchooldata] = React.useState<any>("");
  const [isLogin, setIsLogin] = useState(false);

  const dispatch = useDispatch();
  const userLoginData = useSelector((state: any) => state.data.login.value);

  React.useEffect(() => {
    if (!userLoginData.token) {
      setIsLogin(false);
    }

    // console?.log(userLoginData)

    if (userLoginData.token) {
      setIsLogin(true);
    }
    //  (userLoginData.token ?
    //   ''
    //   :
    //     navigate('/sign-in'));
  }, []);
  React.useEffect(() => {
    AuthApis.getSinglePartnerCourse(partnerId?.replace(/-/g, " "))
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          setCourseDetails(response?.data?.data);

          AuthApis.getSinglePartnerSchoolByName(
            response?.data?.data?.school
          ).then((response: AxiosResponse<any>) => {
            if (response?.data) {
              setSchooldata(response?.data?.data);
            }
          });
        }
      })
      .catch(function (error) {
        // handle error
      });
  }, []);

  const priceval = courseDetails?.product_cost;
  const options = { maximumFractionDigits: 2 };
  const formattedNumber = Intl.NumberFormat("en-US", options).format(priceval);

  // const window = new JSDOM('').window;
  // const purify = DOMPurify(window);

  return (
    <>
      <Navbar />
      {/* <!-- Start block --> */}
      <section className="hidden md:block md:pt-20 relative">
        <div
          style={{
            backgroundImage: `url(${hero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
          className="md:bg-cover relative bg-contain mt-[75px] md:mt-0 sm:px-16 md:px-14 px-6 md:pb-20 md:pt-20 pt-2  flex items-start"
        >
          <div className=" text-white md:pt-40 pt-64 md:pb-5 pb-5">
            {/* <img src={configs.partnerSchools + schoolData?.logo} alt={schoolData?.logo} className='h-9 md:h-16' />
                        <h1 className="md:text-5xl text-2xl font-bold md:mt-[12px]">
                            {courseDetails?.title}
                        </h1>
                        <NavLink to={`/school-details/${schoolData?.id}`}>
                            <h1 className="md:text-2xl text-base font-bold md:mt-[12px]">
                                {schoolData?.school_name}
                            </h1>
                        </NavLink>
                        <p className='md:text-base text-sm font-semibold max-w-[430px] md:mt-[10px] capitalize'>{courseDetails?.category}</p>
                     */}
          </div>

          <div
            className="h-40 absolute top-2 text-white text-[32px] rounded-lg left-6 shadow-lg md:h-40 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            {" "}
            ←{" "}
          </div>

          {/* <img src={schoolData?.logo_url} alt={schoolData?.logo} className='h-40 absolute bottom-[-50px] rounded-[26px] md:left-28 left-5 shadow-lg md:h-40' /> */}
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Mobile section --> */}
      {/* <!-- Start block --> */}
      <section className="block md:hidden md:pt-20 relative">
        <div
          style={{
            backgroundImage: `url(${herosmall})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
          className="md:bg-cover relative bg-contain mt-[75px] md:mt-0 sm:px-16 md:px-14 px-6 md:pb-20 md:pt-20 pt-2  flex items-start"
        >
          <div className=" text-white md:pt-40 pt-64 md:pb-5 pb-5"></div>

          <div
            className="h-40 absolute top-2 text-white text-[32px] rounded-lg left-6 shadow-lg cursor-pointer"
            onClick={() => navigate(-1)}
          >
            {" "}
            ←{" "}
          </div>

          {/* <img src={schoolData?.logo_url} alt={schoolData?.logo} className='h-[130px] absolute bottom-[-70px] rounded-[10px] md:left-28 left-5 shadow-lg  mb-5' /> */}
        </div>
      </section>
      {/* <!-- Start block --> */}

      {/* <!-- Start block --> */}
      <div className=" sm:px-16 px-6 md:flex md:justify-center items-start md:pt-5 pt-10">
        <div className="xl:max-w-[1200px] w-full mx-auto">
          <div className="flex md:flex-row flex-col sm:py-16 py-6 relative">
            <div className="basis-8/12">
              <div className=" text-[#000]  md:pb-5 pb-5">
                <h1 className="text-[24px] md:text-[42px] text-[#1DB459] font-[600]">
                  {courseDetails?.title}
                </h1>
                {/* <NavLink to={`/school-details/${schoolData?.id}`}>
                                    <h1 className="md:text-[24px] text-[16px] font-[500] capitalize">
                                        {schoolData?.school_name}
                                    </h1>
                                </NavLink> */}
                {/* <h5 className='md:text-[24px] text-[16px] font-[400] capitalize'>Tampere, Finland</h5> */}
                <h5 className="md:text-[24px] text-[16px] font-[400] capitalize">
                  {courseDetails?.ects}
                </h5>
              </div>

              <div className="flex md:hidden justify-between  mb-3 gap-2 mt-[10px]">
                {isLogin ? (
                  <NavLink
                    to={`/${courseDetails?.video_url}`}
                    className=" w-full"
                  >
                    <button
                      type="button"
                      className=" bg-[#1DB459]   text-[#FFFFFF] rounded-full md:text-[18px] text-[16px] px-6 min-w-full  py-3 shadow-md"
                    >
                      Apply
                    </button>
                  </NavLink>
                ) : (
                  <NavLink to={"/sign-in"} className=" w-full">
                    <button
                      type="button"
                      className=" bg-[#1DB459]   text-[#FFFFFF] rounded-full md:text-[18px] text-[16px] px-6 min-w-full  py-3 shadow-md"
                    >
                      Apply
                    </button>
                  </NavLink>
                )}

                {/* <NavLink to={`/school-details/${schoolData?.id}`} className=" w-full pr-3">
                                    <button type="button" className="  bg-[#56D9F5] font-[500] text-[#001F26] rounded-full text-[14px] px-6 min-w-full  py-3 shadow-md">About School</button>
                                </NavLink> */}
              </div>

              <hr className="pb-3 md:hidden" />

              <div className="grid md:grid-cols-3 grid-cols-2 md:space-x-5 pb-5 ">
                <div className="bg-[#F0F5FE] font-medium mr-4 md:px-2 px-5 md:py-2 py-1 rounded shadow">
                  <div className="md:text-[30px] text-[18px] text-[#4D4D4D] font-bold text-center">
                    € {formattedNumber}
                  </div>
                  <div className="md:text-[16px] text-[12px] text-[#4D4D4D] text-center">
                    Per Academic Year
                  </div>
                </div>
                <div className="bg-[#F0F5FE] font-medium mr-2 px-6  py-1 rounded shadow">
                  <h3 className="md:text-[30px] text-[18px] text-[#4D4D4D] font-bold text-center capitalize">
                    {courseDetails?.duration}
                  </h3>
                  <h5 className="md:text-[16px] text-[12px] text-[#4D4D4D] text-center">
                    Duration
                  </h5>
                </div>

                <div className="md:flex md:flex-col md:px-1 hidden">
                  {isLogin ? (
                    <NavLink
                      to={`/${courseDetails?.video_url}`}
                      className=" w-full"
                    >
                      <button
                        type="button"
                        className=" bg-[#1DB459]  text-[#FFFFFF] rounded-full text-[16px]  px-6 min-w-full  py-1.5 shadow-md"
                      >
                        Apply
                      </button>
                    </NavLink>
                  ) : (
                    <NavLink to={"/sign-in"} className=" w-full">
                      <button
                        type="button"
                        className=" bg-[#1DB459]  text-[#FFFFFF] rounded-full text-[16px]  px-6 min-w-full  py-1.5 shadow-md"
                      >
                        Apply
                      </button>
                    </NavLink>
                  )}
                  {/* <NavLink to={`/school-details/${schoolData?.id}`} className="mt-2 w-full">
                                        <button type="button" className="bg-[white] text-[#001F26] border border-[#1DB459] font-[500] rounded-full  text-[16px] px-6 min-w-full  py-1.5 shadow-md">About School</button>
                                    </NavLink> */}
                </div>
              </div>
              <hr className="pt-3 hidden md:block" />

              <div className="mt-3">
                {/* <h1 className=' font-[700] text-[24px]'>Course Overview</h1> */}
                <p
                  className="pb-[24px] pt-[8px] "
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(courseDetails?.overview),
                  }}
                ></p>
              </div>
            </div>
            <div className="relative basis-4/12 md:ml-[100px]">
              <div className="mt-10 md:mt-0 sticky top-0 left-0">
                <div className="bg-white p-3 rounded flex-col border border-[#D9D9D9]">
                  <div className="flex-1 flex justify-center items-center">
                    <img
                      src={courseDetails?.image_url}
                      alt="hero"
                      className="w-full"
                    />
                  </div>
                  <div className="text-white mt-1 bg-[#1DB459] text-center w-full font-medium rounded-[4px] text-sm  py-2.5">
                    Key Information
                  </div>
                  <div className="flex justify-between pt-2 mt-1 mb-2 bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_1} </h3>
                    <h3 className="text-[12px]">{courseDetails?.value_1}</h3>
                  </div>
                  <div className="flex justify-between pt-2 mb-2  bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_2} </h3>
                    <h3 className="text-[12px]">{courseDetails?.value_2}</h3>
                  </div>
                  <div className="flex justify-between pt-2 mb-2  bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_3}</h3>
                    <h3 className="text-[12px]">{courseDetails?.value_3}</h3>
                  </div>
                  <div className="flex justify-between pt-2 mb-2 bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_4}</h3>
                    <h3 className="text-[12px] capitalize">
                      {courseDetails?.value_4}
                    </h3>
                  </div>
                  <div className="flex justify-between mb-2 pt-2  bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_5}</h3>
                    <h3 className="text-[12px] capitalize">
                      {courseDetails?.value_5}
                    </h3>
                  </div>
                  <div className="flex justify-between mb-2 pt-2  bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_6} </h3>
                    <h3 className="text-[12px] capitalize">
                      {courseDetails?.value_6}
                    </h3>
                  </div>
                  {/* <div className='flex justify-between pt-2 mt-1 mb-2 bg-[#F6FBFA] px-2 pb-1'>
                                        <h3 className='text-[12px]'>Study Mode </h3>
                                        <h3 className='text-[12px]'>Full Time</h3>
                                    </div>
                                    <div className='flex justify-between pt-2 mb-2  bg-[#F6FBFA] px-2 pb-1'>
                                        <h3 className='text-[12px]'>Duration </h3>
                                        <h3 className='text-[12px]'>{courseDetails?.duration}</h3>
                                    </div>
                                    <div className='flex justify-between pt-2 mb-2  bg-[#F6FBFA] px-2 pb-1'>
                                        <h3 className='text-[12px]'>Campus </h3>
                                        <h3 className='text-[12px]'>{courseDetails?.school}</h3>
                                    </div>
                                    <div className='flex justify-between pt-2 mb-2 bg-[#F6FBFA] px-2 pb-1'>
                                        <h3 className='text-[12px]'>Category </h3>
                                        <h3 className='text-[12px] capitalize'>{courseDetails?.category}</h3>
                                    </div>
                                    <div className='flex justify-between mb-2 pt-2  bg-[#F6FBFA] px-2 pb-1'>
                                        <h3 className='text-[12px]'>Tags </h3>
                                        <h3 className='text-[12px] capitalize'>{courseDetails?.category}</h3>
                                    </div>
                                    <div className='flex justify-between mb-2 pt-2  bg-[#F6FBFA] px-2 pb-1'>
                                        <h3 className='text-[12px]'>Start Date </h3>
                                        <h3 className='text-[12px] capitalize'>24th & 25th of November</h3>
                                    </div> */}
                  <div className="flex justify-between pt-2  bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">Language </h3>
                    <h3 className="text-[12px]">English</h3>
                  </div>

                  <div className="mt-[10px]">
                    {/* practical-nursing-application-form */}

                    <div className="">
                      {isLogin ? (
                        <NavLink to={`/${courseDetails?.video_url}`}>
                          <button
                            type="submit"
                            className="text-white bg-[#1DB459] w-full font-medium rounded-[4px] text-sm  py-2.5"
                          >
                            Apply Now
                          </button>
                        </NavLink>
                      ) : (
                        <NavLink to={"/sign-in"}>
                          <button
                            type="submit"
                            className="text-white bg-[#1DB459] w-full font-medium rounded-[4px] text-sm  py-2.5"
                          >
                            Apply Now
                          </button>
                        </NavLink>
                      )}
                    </div>

                    <div className="mt-[10px] mb-3">
                      <div className="">
                        <div className="text-[20px] font-medium text-[#000F2F]">
                          Need help?{" "}
                        </div>
                        <NavLink to="/contact-us">
                          <div className=" text-[#1DB459] font-[400] text-[14px] cursor-pointer">
                            contact us for more information
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='mt-10 md:mt-[80px]'>
                            <div className='bg-white p-3 rounded flex-col border border-[#D9D9D9]'>
                                <h3 className=" mb-2 text-[20px] font-medium text-[#002147] ">Related Courses</h3>
                                <div className='grid grid-cols-2 md:gap-10 gap-3 md:grid-cols-1 md:mt-[25px] mt-[30px]'>
                                    <div className='flex flex-col md:flex-row md:space-x-3'>
                                        <div> <img src="/images/dev-img.png" alt="hero" className='' /></div>
                                        <div>
                                            <h3 className='text-[#000F2F] font-semibold text-[16px]'>Development</h3>
                                            <h5 className='text-[#949DB1] font-normal text-[14px]'>$54.00</h5>
                                        </div>
                                    </div>
                                    <div className='flex flex-col md:flex-row md:space-x-3'>
                                        <div> <img src="/images/dev-img.png" alt="hero" className='' /></div>
                                        <div>
                                            <h3 className='text-[#000F2F] font-semibold text-[16px]'>Development</h3>
                                            <h5 className='text-[#949DB1] font-normal text-[14px]'>$54.00</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <Footer />
      </div>

      {/* <!-- End block --> */}
    </>
  );
};

export default CourseDetails;
