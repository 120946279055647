import { AxiosPromise } from "axios";
import configs from "../configs";
import { AdinLiveApis } from "./live/adminLiveApi";



export class AdminApis {
    private static adminApi: AdinLiveApis = new AdinLiveApis();  
    
    
    
    static getAllUsers(pageNo:any,query:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getAllUsers(pageNo,query);
        }
    }  

    static getAllAgents(pageNo:any,query:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getAllAgents(pageNo,query);
        }
    } 

    static getWinterStudent(pageNo:any,query:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getWinterStudent(pageNo,query);
        }
    } 

    static getMasterStudent(pageNo:any,query:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getMasterStudent(pageNo,query);
        }
    } 
    
    static getAllGroups(pageNo:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getAllGroups(pageNo);
        }
    }  

    static updateGroupLink(id:any,data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.updateGroupLink(id,data);
        }
    }

    static getUsers(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getUsers();
        }
    } 


    static getAgents(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getAgents();
        }
    } 


    static getMasters(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getMasters();
        }
    } 


    static getWinters(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getWinters();
        }
    } 


    static getBlogs(pageNo:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getBlogs(pageNo);
        }
    } 

    

    static getPaidStudents(pageNo:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getPaidStudents(pageNo);
        }
    }    

    static createGroup(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.createGroup(data);
        }
    }  

    static createPartnerSchool(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.createPartnerSchool(data);
        }
    } 

    static createPartnerCourse(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.createPartnerCourse(data);
        }
    } 

    static getSinglePartnerCourse2(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getSinglePartnerCourse2(data);
        }
    } 

    static updatePartnerSchool(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.updatePartnerSchool(data);
        }
    } 

    static updateAgentStatus(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.updateAgentStatus(data);
        }
    } 

    static updatePartnerCourse(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.updatePartnerCourse(data);
        }
    } 

    


    static createBlog(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.createBlog(data);
        }
    }  


    static updateBlog(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.updateBlog(data);
        }
    } 

    static updateProgram(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.updateProgram(data);
        }
    } 

    static getPaymentDetails(pageNo:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getPaymentDetails(pageNo);
        }
    }  


    static getUserDetailsById(id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getUserDetailsById(id);
        }
    } 

    static getAllSubAdmins(pageNo:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getAllSubAdmins(pageNo);
        }
    }   

    static deletePartnerSchool(id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.deletePartnerSchool(id);
        }
    }   

    static deletePartnerCourse(id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.deletePartnerCourse(id);
        }
    }  

    static deleteBlog(id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.deleteBlog(id);
        }
    }  


    static getUserCount(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getUserCount();
        }
    } 

    static getPaymentData(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getPaymentData();
        }
    } 

    

    static getCookProgram(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getCookProgram();
        }
    } 

    static deleteUser(id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.deleteUser(id);
        }
    } 

    

    

    static getSinglePartnerSchool(id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getSinglePartnerSchool(id);
        }
    } 

    static getPartnerSchool(pageNo:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getPartnerSchool(pageNo);
        }
    } 

    static getPartnerCourse(pageNo:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getPartnerCourse(pageNo);
        }
    }

    static createFaq(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.createFaq(data);
        }
    } 

    static updateFaq(id:any, data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.updateFaq(id, data);
        }
    } 

    static getAllFaq(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getAllFaq();
        }
    }

    static deleteFaq(id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.deleteFaq(id);
        }
    }  
    static getSingleBlog(id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getSingleBlog(id);
        }
    }

   

    
    

    

}