import React from 'react'
import AdminSidebar from '../../Sidebar/AdminSidebar';
import UserNavbar from '../../Navbars/UserNavbar'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AuthApis } from '../../../apis/authApis';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { AdminApis } from '../../../apis/adminApi';
import { useNavigate } from "react-router-dom";
//@ts-ignore
import EllipsisText from "react-ellipsis-text";
import configs from '../../../configs';
//@ts-ignore
import Modal from 'react-awesome-modal';

function EditCourse() {

  const [schoolList, setSchoolList] = React.useState<any>([])
  React.useEffect(() => {
    AdminApis.getPartnerSchool('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data?.success) {
          setSchoolList(response?.data?.data)
        } else {
          // dispatch(login([]))
          // navigate('/login')
        }
      }
    );

  }, [])

  var fontSizeStyle = Quill.import('attributors/style/size');
  fontSizeStyle.whitelist = ['12px', '14px', '20px', '24px'];
  Quill.register(fontSizeStyle, true);


  

  var toolbarOptions = [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'size': fontSizeStyle.whitelist }],
    [{ 'font': [5, 6, 7, 8, 9, 10, 11, 12, 131, 4, 16, 19, 23] }],
    ['bold', 'italic', 'underline', 'link'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'align': [] }],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    ['clean']                                         // remove formatting button
  ];
  const module = {
    toolbar: toolbarOptions
  }



  const params: any = useParams();
  let partnerId: string = params?.courseId;



  let [visible, setVisible] = React.useState(false);
  function toggleModal() {
    setVisible(!visible)
  }

  const deleteCourse = React.useCallback(
    (e: any) => {
      AdminApis.deletePartnerCourse(partnerId).then(
        (response: any) => {
          if (response?.data) {
            console.log(response?.data?.data)
            toast.success(response?.data?.message);
            setVisible(false)
            navigate('/course-list');
          }
        }
      ).catch((err: any) => {
        if (err?.response?.status == 422) {
          //   toast.error('Form not rightly filled. Kindly Cross check.');
        } else {
          //   toast.error('Some error occured.Please try again');
        }


      })
    },
    []
  );

  const [fetchedData, setFetchedData] = React.useState<any>([])






  const [coverPhotoName, SetCoverPhotoName] = React.useState<any>(fetchedData?.cover_photo)
  const [courseOverview, setCourseOverview] = React.useState<any>(fetchedData?.overview)
  const [aimOfTraining, setAimOfTraining] = React.useState<any>(fetchedData?.training_aim)
  const [adminssionRequirement, setAdmissionrequirement] = React.useState<any>(fetchedData?.admission_requirement)
  const [courseOutline, setCourseOutline] = React.useState<any>(fetchedData?.course_outline)
  const [career, setCareer] = React.useState<any>(fetchedData?.career)


  React.useEffect(() => {
    AdminApis.getSinglePartnerCourse2(partnerId).then(
      (response: AxiosResponse<any>) => {
        if (response?.data?.success) {
          console?.log(response?.data?.data)
          setFetchedData(response?.data?.data)
          SetCoverPhotoName(response?.data?.data?.cover_photo)
          setCourseOverview(response?.data?.data?.overview)
          setAimOfTraining(response?.data?.data?.training_aim)
          setAdmissionrequirement(response?.data?.data?.admission_requirement)
          setCourseOutline(response?.data?.data?.course_outline)
          setCareer(response?.data?.data?.career)
        } else {
          // dispatch(login([]))
          // navigate('/login')
        }
      }
    );

  }, [])



  const [errorMes, setErrorMes] = React.useState<any>("")
  const navigate = useNavigate();


  let [userData, setUserData] = React.useState({
    title: '', product_cost: "",
    tags: " ", school: "",
    category: "", ects: "",
    duration: "", website_url: "",
    twitter_link: "", instagram_link: "",
    facebook_link: "", video_url: "",
    key_1: "", value_1: "",
    key_2: "", value_2: "",
    key_3: "", value_3: "",
    key_4: "", value_4: "",
    key_5: "", value_5: "",
    key_6: "", value_6: "",
  });

  const handleChange = (e: any) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  }

  const [coverPhoto, setCoverPhoto] = React.useState('No selected file');
  const [coverPhotoPreview, setCoverPhotoPreview] = React.useState('No selected file');
  const coverPhotohandler = (e: any) => {
    // console?.log(e.target.files)
    const target = e.target
    let size = (target.files[0].size / 1048576.0)
    let targetName = target.name

    SetCoverPhotoName(e.target.files[0].name)
    // console?.log(size)
    if (target.files && target.files[0]) {
      if (size > 3) {
        if (targetName == 'coverPhoto') {
          setErrorMes("File too big!")
          toast.error("File is greater than 3MB!");
          target.value = ''
          console.log('error')
        }
      }
      if (size <= 3) {
        if (targetName == 'coverPhoto') {
          setErrorMes(" ")
          setCoverPhoto(e.target.files[0]);
          setCoverPhotoPreview(URL.createObjectURL(e.target.files[0]));

        }
      }
    }
  };


  const createSchoolProgram = React.useCallback(
    (e: any) => {
      e.preventDefault();
      const blogData = new FormData();
      blogData.append('title', (userData?.title == '' ? fetchedData?.title : userData?.title));
      blogData.append('overview', courseOverview);
      blogData.append('training_aim', aimOfTraining);
      blogData.append('admission_requirement', adminssionRequirement);
      blogData.append('course_outline', courseOutline);
      blogData.append('career', career);
      blogData.append('id', partnerId);
      blogData.append('category', (userData?.category == '' ? fetchedData?.category : userData?.category));
      blogData.append('product_cost', (userData?.product_cost == '' ? fetchedData?.product_cost : userData?.product_cost));
      blogData.append('tags', (userData?.tags == '' ? fetchedData?.tags : userData?.tags));
      blogData.append('school', (userData?.school == '' ? fetchedData?.school : userData?.school));
      blogData.append('ects', (userData?.ects == '' ? fetchedData?.ects : userData?.ects));
      blogData.append('duration', (userData?.duration == '' ? fetchedData?.duration : userData?.duration));
      blogData.append('website_url', (userData?.website_url == '' ? fetchedData?.website_url : userData?.website_url));
      blogData.append('twitter_link', (userData?.twitter_link == '' ? fetchedData?.twitter_link : userData?.twitter_link));
      blogData.append('instagram_link', (userData?.instagram_link == '' ? fetchedData?.instagram_link : userData?.instagram_link));
      blogData.append('facebook_link', (userData?.facebook_link == '' ? fetchedData?.facebook_link : userData?.facebook_link));
      blogData.append('cover_photo', coverPhoto);
      blogData.append('video_url', (userData?.video_url == '' ? fetchedData?.video_url : userData?.video_url));
      blogData.append('key_1', (userData?.key_1 == '' ? fetchedData?.key_1 : userData?.key_1));
      blogData.append('value_1', (userData?.value_1 == '' ? fetchedData?.value_1 : userData?.value_1));
      blogData.append('key_2', (userData?.key_2 == '' ? fetchedData?.key_2 : userData?.key_2));
      blogData.append('value_2', (userData?.value_2 == '' ? fetchedData?.value_2 : userData?.value_2));
      blogData.append('key_3', (userData?.key_3 == '' ? fetchedData?.key_3 : userData?.key_3));
      blogData.append('value_3', (userData?.value_3 == '' ? fetchedData?.value_3 : userData?.value_3));
      blogData.append('key_4', (userData?.key_4 == '' ? fetchedData?.key_4 : userData?.key_4));
      blogData.append('value_4', (userData?.value_4 == '' ? fetchedData?.value_4 : userData?.value_4));
      blogData.append('key_5', (userData?.key_5 == '' ? fetchedData?.key_5 : userData?.key_5));
      blogData.append('value_5', (userData?.value_5 == '' ? fetchedData?.value_5 : userData?.value_5));
      blogData.append('key_6', (userData?.key_6 == '' ? fetchedData?.key_6 : userData?.key_6));
      blogData.append('value_6', (userData?.value_6 == '' ? fetchedData?.value_6 : userData?.value_6));

      AdminApis.updatePartnerCourse(blogData).then(
        (response: any) => {
          if (response?.data) {
            console.log(response?.data?.data)
            toast.success(response?.data?.message);
            navigate('/course-list');
          }
        }
      ).catch((err: any) => {
        if (err?.response?.status === 422) {
          console?.log(err)
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(err?.response?.data?.message);
        }


      })
    },
    [userData, coverPhoto, courseOutline, courseOverview, aimOfTraining, career]
  );
  return (
    <>
      <AdminSidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar />
        <div className="lg:pt-10 lg:px-10 px-6 ">
          <div className='flex justify-between'>
            <h3 className=' font-bold text-[31px]'>Create Course</h3>
            <span> <button type='button' onClick={toggleModal} className="text-white bg-[#b41d1d] px-3 py-1 rounded-md"> Delete</button></span>

          </div>
        </div>
        <div className="relative overflow-x-auto shadow-md rounded-lg border-2 border-[#D9D9D9] mx-6 lg:mt-4 lg:mx-10 mt-5">
          <form onSubmit={createSchoolProgram}>
            <div className='flex md:flex-row flex-col sm:pt-10 py-6 md:px-8 px-4'>
              <div className='basis-8/12'>
                {/* upload image */}
                <div className="w-full lg:w-12/12">
                  <div className="flex items-center justify-center w-full">
                    <label className="flex flex-col items-center justify-center w-full h-[96px] rounded-[5px] cursor-pointer my-4 mb-6">
                      {/* <div className="flex flex-col items-center justify-center pt-6 "> */}
                      <img className="" src={coverPhoto == 'No selected file' ? fetchedData?.image_url : coverPhotoPreview} alt={fetchedData?.cover_photo} style={{ maxHeight: "10vh", minWidth: "50%", maxWidth: '50%', minHeight: "20vh" }} />
                      {/* </div> */}
                      <input id="dropzone-file" name='coverPhoto' onChange={coverPhotohandler} type="file" className={"hidden mb-2 text-sm text-[#6C757D] font-medium"} />
                    </label>
                  </div>
                </div>
                <div className='mt-[30px]'>
                  <h3 className='text-[#1DB459] text-[15px] font-semibold'>Basic Information</h3>
                  <div className="w-full lg:w-12/12  pt-4">
                    <div className="relative w-full mb-6">
                      <label
                        className="block text-[#303840] text-sm font-medium md:pl-1 mb-2"
                        htmlFor="grid-password"
                      >
                        Course Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        defaultValue={fetchedData?.title}
                        onChange={handleChange}
                        className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                        placeholder=""

                      />
                    </div>
                  </div>
                </div>

                <div className="w-full lg:w-12/12 md:mb-[60px] mb-[120px]">
                  <div className='mb-[10px] text-[#303840] text-[13px] font-medium'>Course overview</div>
                  <ReactQuill theme="snow"
                    modules={module}
                    value={courseOverview}
                    onChange={setCourseOverview}
                    placeholder={'Content of the Editor'}
                    className='h-60' />
                </div>


                <div className='mt-[120px]'>
                   <div className='mb-[10px] text-[#303840]  font-medium'>Key Information</div>
                  <div className='  border border-[#CBD6E2] rounded-[10px] px-3'>
                    <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6 md:pt-4" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 1
                        </label>
                        <input
                          type="text"
                          name="key_1" onChange={handleChange}
                          defaultValue={fetchedData?.key_1}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6 md:pt-4" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 1
                        </label>
                        <input
                          type="text"
                          name="value_1" onChange={handleChange}
                          defaultValue={fetchedData?.value_1}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                      <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 2
                        </label>
                        <input
                          type="text"
                          name="key_2" onChange={handleChange}
                          defaultValue={fetchedData?.key_2}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 2
                        </label>
                        <input
                          type="text"
                          name="value_2" onChange={handleChange}
                          defaultValue={fetchedData?.value_2}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                      <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 3
                        </label>
                        <input
                          type="text"
                          name="key_3" onChange={handleChange}
                          defaultValue={fetchedData?.key_3}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 3
                        </label>
                        <input
                          type="text"
                          name="value_3" onChange={handleChange}
                          defaultValue={fetchedData?.value_3}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                      <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 4
                        </label>
                        <input
                          type="text"
                          name="key_4" onChange={handleChange}
                          defaultValue={fetchedData?.key_4}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6 " >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 4
                        </label>
                        <input
                          type="text"
                          name="value_4" onChange={handleChange}
                          defaultValue={fetchedData?.value_4}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                      <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6 " >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 5
                        </label>
                        <input
                          type="text"
                          name="key_5" onChange={handleChange}
                          defaultValue={fetchedData?.key_5}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 5
                        </label>
                        <input
                          type="text"
                          name="value_5" onChange={handleChange}
                          defaultValue={fetchedData?.value_5}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                      <div className='flex md:flex-row flex-col gap-5'>
                  <div className="w-full  mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Key 6
                        </label>
                        <input
                          type="text"
                          name="key_6" onChange={handleChange}
                          defaultValue={fetchedData?.key_6}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      <div className="w-full mb-6" >
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Value 6
                        </label>
                        <input
                          type="text"
                          defaultValue={fetchedData?.value_6}
                          name="value_6" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                      </div>
                  </div>
                  </div>

              </div>
              <div className='basis-4/12 md:ml-[66px]'>
                <div className='mt-10 md:mt-0'>
                  <h3 className='mb-3 text-[#303840] font-medium'>Video</h3>
                  <div className=' flex-col border border-[#CBD6E2] rounded-[10px] px-3'>
                    <div>
                      <div className="w-full lg:w-12/12  md:pt-4">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#303840] text-sm font-medium md:pl-1 mb-2 mt-1"
                            htmlFor="grid-password"
                          >
                            URL
                          </label>
                          <input
                            type="text"
                            defaultValue={fetchedData?.video_url}
                            name="video_url" onChange={handleChange}
                            className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                            placeholder=""

                          />
                          <span className='text-[12px] font-medium text-[#6E768C]'>Enter a valid video URL.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mt-10'>
                  <h3 className='text-[#303840] my-3 font-medium'>Options</h3>
                  <div className=' flex-col border border-[#CBD6E2] rounded-[10px] px-3'>
                    <div>
                      <div className="w-full lg:w-12/12  md:pt-4">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                            htmlFor="grid-password"
                          >
                            Category
                          </label>
                          <select
                            id="category"

                            name="category" onChange={handleChange}
                            className=" border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg  block w-full p-2.5 "
                          >
                            <option selected>{fetchedData?.title}</option>
                            <option value="vocational">Vocational</option>
                            {/* <option value="high school">High School</option> */}
                            <option value="degree program">Degree Program</option>
                            <option value="master's program">Master's Program</option>
                          </select>
                          <span className='text-[12px] font-medium text-[#6E768C]'>Select a category.</span>
                        </div>
                      </div>
                      <div className="w-full lg:w-12/12 mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Product Cost
                        </label>
                        <div className="flex">
                          <span className="inline-flex items-center px-3 text-sm text-gray-900 border border-r-0 border-gray-300 rounded-l-md ">
                            €
                          </span>
                          <input type="number" defaultValue={fetchedData?.product_cost} name="product_cost" onChange={handleChange} id="website-admin" className="rounded-none rounded-r-lg  border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5" placeholder="" />
                        </div>
                        <span className='text-[12px] font-medium text-[#6E768C]'>The recommended price is between €10,700 and €12,400</span>
                      </div>

                      <div className="w-full lg:w-12/12 mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Tags
                        </label>
                        <input
                          type="text"
                          defaultValue={fetchedData?.tags}
                          name="tags" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                        <span className='text-[12px] font-medium text-[#6E768C]'>Input one or more tags.</span>
                      </div>

                      <div className="w-full lg:w-12/12 mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Campus ({fetchedData?.school})
                        </label>
                        <select
                          id="category"
                          name="school" onChange={handleChange}
                          className=" border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg  block w-full p-2.5 "
                        >
                          <option selected></option>

                          {schoolList?.data?.map(
                            (datas: any, index: any) => (
                              <option value={datas?.school_name}>{datas?.school_name}</option>
                            )
                          )
                          }

                        </select>
                      </div>

                      <div className="w-full lg:w-12/12 mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Location
                        </label>
                        <input
                          type="text"
                          defaultValue={fetchedData?.ects}
                          name="ects" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>

                      <div className="w-full lg:w-12/12 mb-6">
                        <label
                          className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                          htmlFor="grid-password"
                        >
                          Duration
                        </label>
                        <input
                          type="text"
                          defaultValue={fetchedData?.duration}
                          name="duration" onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-4/12 md:px-8 px-6 pb-6">
              <div className=' items-center'>
                <button type='submit' className="font-normal text-white bg-[#1DB459] px-6 py-2.5 rounded-[3px]">Update Course</button>
              </div>
            </div>
          </form>

        </div>
      </div>

      <section>
        <Modal
          visible={visible}
          width="700"
          minHeight="300"
          effect="fadeInUp"
          onClickAway={() => toggleModal}
        >
          <p className="flex justify-end mt-2 ml-2 mr-2 cursor-pointer font-bold" onClick={toggleModal}>X</p>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-xl rounded-lg mt-2" >
            <div className="px-6">

              <div className=''>
                <div className='flex justify-center'>Are you sure you want to delete this Partner?</div>
                <div className='flex justify-center py-10'><button type='button' onClick={deleteCourse} className="text-white bg-[#b41d1d] px-3 py-1 rounded-md "> Proceed</button></div>
              </div>



            </div>
          </div>
        </Modal>
      </section>


      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

    </>
  )
}

export default EditCourse;