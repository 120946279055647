import React from "react";
import Navbar from "../Navbar";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import hero from "../../img/course-bg.png";
import { AuthApis } from "../../apis/authApis";
import { AxiosResponse } from "axios";
import configs from "../../configs";
import { MutatingDots } from "react-loader-spinner";

const CourseList = () => {
  const location = useLocation();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const navigate = useNavigate();
  const foo = params.get("p")?.split("-").join(" ");

  // console?.log(foo)

  const [wishlistText, setWishlistText] = React.useState<any>("");
  const [wishlistTextCook, setWishlistTextCook] = React.useState<any>("");
  const [courseList, setCourseList] = React.useState<any>("");
  const [title, setTitle] = React.useState<any>(location?.state?.name);
  const [category, setCategory] = React.useState<any>(
    location?.state?.category
  );
  const [loader, setLoader] = React.useState<boolean>(true);

  // if(foo){
  //     setCategory(foo);
  // }

  // console?.log(courseList);

  React.useEffect(() => {
    setLoader(true);
    const query: any = {
      title: title ? title : "",
      category: foo
        ? foo == "masters program"
          ? "master's program"
          : foo
        : category
        ? category
        : "",
    };

    // console?.log(category)
    AuthApis.getFilteredCourse("", query)
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          setLoader(false);
          setCourseList(response?.data?.data);
          console.log(response?.data?.data);
        }
      })
      .catch(function (error) {
        // handle error
      });
  }, [foo]);

  const redirect = React.useCallback(() => {
    if (category == "master's program") {
      navigate("/list-of-courses?p=masters-program");
    } else if (category == "vocational") {
      navigate("/list-of-courses?p=vocational");
    } else if (category == "degree program") {
      navigate("/list-of-courses?p=degree-program");
    } else if (category == "high school") {
      navigate("/list-of-courses?p=high-school");
    } else {
      navigate("/list-of-courses");
    }
  }, [category]);

  const paginator = React.useCallback(
    (value: any) => {
      //   setLoader(true);
      let value2 = "";
      if (value !== null) {
        value2 = value;
      } else {
        value2 = "";
      }
      const query: any = {
        title: title ? title : "",
        category: category ? category : "",
      };
      // console?.log(value2)

      AuthApis.getFilteredCourse(value2, query)
        .then((response: AxiosResponse<any>) => {
          if (response?.data) {
            setCourseList(response?.data?.data);
          }
        })
        .catch(function (error) {
          console.log(error.response.data);
        });
    },
    [courseList, title, category]
  );

  return (
    <>
      <Navbar />
      {/* <!-- Start block --> */}
      <section className="body-font font-poppins md:pt-20 relative">
        <div
          style={{
            backgroundImage: `url(${hero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="sm:px-16 md:px-14 px-6 md:pb-44 pb-30 md:pt-16 pt-10  flex justify-center items-center"
        >
          <div className="place-self-center text-center text-white pt-20">
            <h1 className="md:text-5xl text-3xl font-bold">
              List of Courses
              <br /> we have.
            </h1>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <div className=" sm:px-16 px-6 md:flex md:justify-center items-start pt-10">
        <div className="xl:max-w-[1200px] w-full mx-auto">
          <div className="flex md:flex-row flex-col sm:py-16 py-6">
            <div className="basis-4/12">
              <div className="grid md:grid-cols-1 gap-4 grid-cols-1">
                <div className="">
                  <label
                    htmlFor="default-input"
                    className="block mb-2 md:text-[24px] text-[20px] font-medium text-[#002147]"
                  >
                    Search
                  </label>

                  <label
                    htmlFor="countries"
                    className="block mb-2 md:text-[14px] text-[12px] font-bold text-[#002147] "
                  >
                    Course Name
                  </label>
                  <input
                    type="text"
                    id="default-input"
                    defaultValue={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="bg-white border border-[#E3E3E3] text-gray-900 text-sm rounded-[4px] block w-full p-2.5"
                  />
                </div>

                <div>
                  <label
                    htmlFor="countries"
                    className="block mb-2 md:text-[14px] text-[12px] font-bold text-[#002147] capitalize "
                  >
                    Mode of Study ({category})
                  </label>
                  <select
                    id="countries"
                    onChange={(e) => setCategory(e.target.value)}
                    className="bg-white border border-[#E3E3E3] text-gray-900 text-sm rounded-[4px] block w-full p-2.5 "
                  >
                    <option value="">all</option>
                    {/* <option value="high school">High School</option> */}
                    <option value="vocational">Vocational</option>
                    <option value="degree program">Degree Program</option>
                    <option value="master's program">Master's Program</option>
                  </select>
                </div>
                <div className="mt-4 md:mt-0">
                  <button
                    type="button"
                    onClick={() => redirect()}
                    className="text-white bg-[#1DB459] w-full font-medium rounded-[4px] text-sm  py-2.5"
                  >
                    <span className="flex justify-center">
                      <span className="">
                        <svg
                          className="w-3.5 h-3.5 mr-2 mt-1 text-white "
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </span>

                      <span> Search</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="basis-8/12 md:ml-[100px] ">
              {loader ? (
                <div className="flex justify-center mt-52">
                  <MutatingDots
                    height="100"
                    width="100"
                    color="#4fa94d"
                    secondaryColor="#4fa94d"
                    radius="12.5"
                    ariaLabel="mutating-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={loader}
                  />
                </div>
              ) : (
                <div className="min-h-[80vh]">
                  <div className="grid md:grid-cols-2 md:gap-10 gap-3 grid-cols-1 md:mt-[20px] mt-[40px]">
                    {
                    courseList?.data?.length > 0 ? (
                      <div className="flex justify-center mt-4">
                      <span> Unavailable at the moment</span>
                    </div>
                      // courseList?.data?.map((datas: any, index: any) => {
                      //   Check if the ID is not 
                      //   if (![134].includes(datas.id)) {
                      //     return (
                      //       <NavLink
                      //         to={`/course-details/${(datas?.title).replace(
                      //           / /g,
                      //           "-"
                      //         )}`}
                      //       >
                      //         <div className="bg-white p-3 rounded flex-col border border-[#D9D9D9]">
                      //           <div className="flex-1 flex justify-center items-center">
                      //             <img
                      //               className=""
                      //               src={datas?.image_url}
                      //               alt={datas?.cover_photo}
                      //               style={{ width: "384px", height: "188px" }}
                      //             />
                      //           </div>

                      //           <div className="flex justify-between pt-2">
                      //             <h3 className="text-[#002147] md:text-[14px] text-[12px] font-normal mt-2">
                      //               {datas?.title}
                      //             </h3>
                      //             <div className="mt-1">➜</div>
                      //           </div>
                      //         </div>
                      //       </NavLink>
                      //     );
                      //   }
                       
                      // })
                    )
                     : (
                      // <div className="flex justify-center mt-4">
                      //   <span>No Result Found for {foo}</span>
                      // </div>
                      <div className="flex justify-center mt-4">
                      <span> Unavailable at the moment</span>
                    </div>
                    )}
                  </div>
                </div>
              )}
              <div className="m-4">
                {loader
                  ? ""
                  : courseList?.links
                      ?.filter((item: any, idx: any) => idx < 1000)
                      .map((datas: any, index: any) => (
                        <button
                          onClick={() =>
                            paginator(
                              datas?.label == "Next &raquo;"
                                ? datas?.url.charAt(datas?.url.length - 1)
                                : datas?.label === "&laquo; Previous"
                                ? datas?.url.charAt(datas?.url.length - 1)
                                : datas?.label
                            )
                          }
                          disabled={datas?.active}
                          className={
                            "mx-1 py-1 px-2 " +
                            (datas?.active == false
                              ? "bg-gray-300 text-black "
                              : "bg-green-500 text-white")
                          }
                        >
                          {datas?.label == "&laquo; Previous"
                            ? "< Previous"
                            : datas?.label === "Next &raquo;"
                            ? "Next  >"
                            : datas?.label}
                        </button>
                      ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End block --> */}
    </>
  );
};

export default CourseList;
